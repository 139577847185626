<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.msi_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.msi_code" 
                    :rules="toValidate(mrValidation.msi_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.msi_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.msi_name" 
                    :rules="toValidate(mrValidation.msi_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Pilih Diagnosa SDKI<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Diagnosa SDKI" v-model="row.msi_msd_id" :options="$parent.mSDKI" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                  <VValidate 
                    name="Diagnosa SDKI" 
                    v-model="row.msi_msd_id" 
                    :rules="toValidate(mrValidation.msi_msd_id)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col lg="12">
            <label for="">Tindakan Observasi & Tindakan Keperawatan</label>
            <table class="table table-hover table-bordered">
              <thead>
                <tr class="table-secondary">
                  <th>Nama</th>
                  <th>Tindakan Keperawatan</th>
                  <th width="100">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in row.msi_tindakan_perawat_observasi" :key="k">
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_observasi'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                  <VValidate 
                    :name="'Name Observasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_observasi'][k]['name']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_observasi'][k]['tindakan']" class="form-control" placeholder="e.g. Tindakan Pertolongan Pertama">
                  <VValidate 
                    :name="'Tindakan Observasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_observasi'][k]['tindakan']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td class="text-center">
                    <i v-if="row.msi_tindakan_perawat_observasi.length > 1" @click="removeRowObservasi(k)" class="icon-trash"></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <div class="text-center">
                      <button type="button" @click="addRowObservasi()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="12">
            <label for="">Tindakan Terapeutik & Tindakan Keperawatan</label>
            <table class="table table-hover table-bordered">
              <thead>
                <tr class="table-secondary">
                  <th>Nama</th>
                  <th>Tindakan Keperawatan</th>
                  <th width="100">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in row.msi_tindakan_perawat_terapeutik" :key="k">
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_terapeutik'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                  <VValidate 
                    :name="'Name Teraputik '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_terapeutik'][k]['name']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_terapeutik'][k]['tindakan']" class="form-control" placeholder="e.g. Tindakan Pertolongan Pertama">
                  <VValidate 
                    :name="'Tindakan Teraputik '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_terapeutik'][k]['tindakan']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td class="text-center">
                    <i v-if="row.msi_tindakan_perawat_terapeutik.length > 1" @click="removeRowTerapeutik(k)" class="icon-trash"></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <div class="text-center">
                      <button type="button" @click="addRowTerapeutik()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="12">
            <label for="">Tindakan Edukasi & Tindakan Keperawatan</label>
            <table class="table table-hover table-bordered">
              <thead>
                <tr class="table-secondary">
                  <th>Nama</th>
                  <th>Tindakan Keperawatan</th>
                  <th width="100">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in row.msi_tindakan_perawat_edukasi" :key="k">
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_edukasi'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                  <VValidate 
                    :name="'Name Edukasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_edukasi'][k]['name']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_edukasi'][k]['tindakan']" class="form-control" placeholder="e.g. Tindakan Pertolongan Pertama">
                  <VValidate 
                    :name="'Tindakan Edukasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_edukasi'][k]['tindakan']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td class="text-center">
                    <i v-if="row.msi_tindakan_perawat_edukasi.length > 1" @click="removeRowEdukasi(k)" class="icon-trash"></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <div class="text-center">
                      <button type="button" @click="addRowEdukasi()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="12">
            <label for="">Tindakan Kolaborasi & Tindakan Keperawatan</label>
            <table class="table table-hover table-bordered">
              <thead>
                <tr class="table-secondary">
                  <th>Nama</th>
                  <th>Tindakan Keperawatan</th>
                  <th width="100">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in row.msi_tindakan_perawat_kolab" :key="k">
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_kolab'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                  <VValidate 
                    :name="'Name Kolaborasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_kolab'][k]['name']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td>
                  <input type="text" v-model="row['msi_tindakan_perawat_kolab'][k]['tindakan']" class="form-control" placeholder="e.g. Tindakan Pertolongan Pertama">
                  <VValidate 
                    :name="'Tindakan Kolaborasi '+(k+1)" 
                    v-model="row['msi_tindakan_perawat_kolab'][k]['tindakan']" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                  />
                  </td>
                  <td class="text-center">
                    <i v-if="row.msi_tindakan_perawat_kolab.length > 1" @click="removeRowKolab(k)" class="icon-trash"></i>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <div class="text-center">
                      <button type="button" @click="addRowKolab()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </b-col>
        </b-row>

      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    
    addRowObservasi(){
      let payload = {
        "name":"",
        "tindakan":"",
      }
      this.row.msi_tindakan_perawat_observasi.push(payload)
    },
    removeRowObservasi(k){
      this.row.msi_tindakan_perawat_observasi.splice(k,1)      
    },

    addRowTerapeutik(){
      let payload = {
        "name":"",
        "tindakan":"",
      }
      this.row.msi_tindakan_perawat_terapeutik.push(payload)
    },
    removeRowTerapeutik(k){
      this.row.msi_tindakan_perawat_terapeutik.splice(k,1)      
    },

    addRowEdukasi(){
      let payload = {
        "name":"",
        "tindakan":"",
      }
      this.row.msi_tindakan_perawat_edukasi.push(payload)
    },
    removeRowEdukasi(k){
      this.row.msi_tindakan_perawat_edukasi.splice(k,1)      
    },

    addRowKolab(){
      let payload = {
        "name":"",
        "tindakan":"",
      }
      this.row.msi_tindakan_perawat_kolab.push(payload)
    },
    removeRowKolab(k){
      this.row.msi_tindakan_perawat_kolab.splice(k,1)      
    },
  },
}
</script>